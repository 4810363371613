@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Suisse Intl";
  src: url(./fonts/suisse/SuisseIntl-Regular-WebXL.woff2) format("woff2"),
    url(./fonts/suisse/SuisseIntl-Regular-WebXL.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Suisse Intl";
  src: url(./fonts/suisse/SuisseIntl-SemiBold-WebXL.woff2) format("woff2"),
    url(./fonts/suisse/SuisseIntl-SemiBold-WebXL.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  margin: 0;
  font-family: "Suisse Intl", "Helvetica", "Arial", sans-serif;
  font-size: 9pt;
  background-color: #fff;
  font-feature-settings: "salt";
  -o-font-feature-settings: "salt";
  -moz-font-feature-settings: "salt";
  -webkit-font-feature-settings: "salt";
}

#root {
  height: 100vh;
  width: 100vw;
}

*:focus {
  outline: none;
}

/* ***************** */
/* REACT DATE PICKER */
/* ***************** */

/* Wrapper / Popover */
.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker-popper {
  margin-top: 0.5rem;
  background-color: #fff;
  border: solid;
  border-width: 1px;
  border-radius: 0.25rem;
  padding: 0.5rem;
  z-index: 999;
  box-shadow: theme(boxShadow.md);
}

/* Navigation icons */
.react-datepicker__navigation-icon:before{
  border-color: theme(colors.gray.400);
  border-style: solid;
  border-width: 0.125rem 0.125rem 0 0;
  content: "";
  display: block;
  height: 0.5rem;
  position: absolute;
  width: 0.5rem;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-align: center;
  text-indent: -999em;
  z-index: 1;
}

.react-datepicker__navigation--next {
  right: 0.5rem;
}

.react-datepicker__navigation-icon {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.react-datepicker__navigation-icon--previous:before {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  left: 0.625rem;
}

.react-datepicker__navigation-icon--previous {
  justify-content: start;
}

.react-datepicker__navigation-icon--next:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 0.625rem;
}

.react-datepicker__navigation-icon--next {
  justify-content: end;
}

.react-datepicker__aria-live {
  border: 0;
  -webkit-clip-path: circle(0);
  clip-path: circle(0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* Calendar header */
.react-datepicker__header {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-bottom: 0.25rem;
  line-height: 2rem;
}

.react-datepicker__current-month {
  text-align: center;
  font-weight: theme(fontWeight.bold);
  font-size: theme(fontSize.sm)
}

.react-datepicker__day-names {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Core calendar */
.react-datepicker__week {
  display: flex;
  flex-direction: row;
}

.react-datepicker__day,
.react-datepicker__day-name {
  display: flex;
  height: 32px;
  width: 32px;
  margin: 0.125rem 0rem;
  justify-content: center;
  align-items: center;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day--outside-month{
  color: theme(colors.gray.400)
}

/* Hover / selected day states */
.react-datepicker__day:hover,
.react-datepicker__day--selected {
  border-radius: 1rem;
  background-color: theme(colors.gray.200);
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0px;
  background-color: theme(colors.gray.200);
}

.react-datepicker__day--in-range:hover {
  border-radius: 0px;
  background-color: theme(colors.gray.300);
}

.react-datepicker__day--range-start:hover,
.react-datepicker__day--selecting-range-start:hover,
.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  border-radius: 1rem 0rem 0rem 1rem;
}

.react-datepicker__day--range-end:hover,
.react-datepicker__day--selecting-range-end:hover,
.react-datepicker__day--range-end,
.react-datepicker__day--selecting-range-end {
  border-radius: 0rem 1rem 1rem 0rem;
}
